body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'SF Pro Text,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif',
    monospace;
}
