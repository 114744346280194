* {
    margin: 0;
    padding: 0;
    scrollbar-width: none;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: 'Nunito, sans-serif', */
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.text-grey-500 {
    color: #787878;
}

.fontSize-12 {
    font-size: 12px;
}



.fontSize-18 {
    font-size: 18px;
}

.fontSize-20 {
    font-size: 20px;
}

.fontSize-24 {
    font-size: 24px;
}

.fontSize-30 {
    font-size: 30px;
}

.fontSize-54 {
    font-size: 54px;
}

.fontWeight-500 {
    font-weight: 500;
}

.fontWeight-600 {
    font-weight: 600;
}

.fontWeight-700 {
    font-weight: 700;
}

.fontWeight-800 {
    font-weight: 800;
}

a {
    text-decoration: none;
}

.navLinkHover:hover {
    color: #0c6efd !important;
}

#basic-nav-dropdown {
    color: #ebebeb !important;
}

#basic-nav-dropdown:hover {
    color: #0c6efd ! important;
}

.dropdown-item:active {
    background-color: inherit;
}

.gradient-overlay {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg, #ffffff, #ffffff, transparent);
}

.cardHeader {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
}

img.img-fluid.GameTitleImg {
    width: 25%;
}

.CardBodyText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 600;
}

.CardBodyText h5 {
    text-align: center;
}

p.parahalf {
    width: 50%;
}

.video-fluid {
    width: 80%;
    height: auto;
    opacity: 0;
    transition: opacity 0.5s ease;
    /* Adjust the duration and easing as needed */
}

.visible {
    opacity: 1;
    border-radius: 10px;
    width: 100%;
}

.hiddenOpacity {
    opacity: 0;
}

.visibleText {
    display: block;
}

.displayNone {
    display: none;
}

.scaleText {
    transform: scale(1);
}

.scaleText {
    transform: scale(1.1);
    transition: 0.3s ease;
    position: absolute;
    z-index: 999;
}

.custom-card.card {
    border: 0.5px solid #00000040;
    min-height: 200px;
    position: relative;
    height: 100;
    /* min-width: 320px; */
    width: 100%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.custom-card.hovered.scaleText.card {
    border: 1px solid #c2c2c245;
    border-radius: 15px;
    width: 100%;
}

.testimonialCard.mb-5.card {
    background: #fff;
    border: 1px solid #000;
    border-radius: 30px;
}

.testimonialCard.mb-5.card .card-footer {
    background: transparent;
    border: 0px;
}

img.profile-image {
    width: 80px;
    border: 1px dashed #000;
    border-radius: 50px;
}

.AboutUs_Section {
    position: relative;
}

.AboutUsbox {
    display: block;
    width: 100%;
    height: 100vh;
    /* Or any specific height you want for centering */
    text-align: center;
    /* Centers the text horizontally */
    line-height: 100vh;
    /* Centers the text vertically by setting the line height */
    position: relative;
}

.AboutUsText h5 {
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
}

span.badgeSpan {
    text-align: left;
    width: 10%;
}

.HowWeDoIt {
    position: relative;
}

.HowTextContent {
    position: absolute;
    width: 100%;
    top: 10%;
    color: #fff;
}

img.DoItBG {
    filter: blur(20px);
    backdrop-filter: brightness(0.5);
    width: 100%;
    margin: 50px 0px;
    padding: 30px 0px;
    border-radius: 10px;
}

.Part {
    border: 4px solid #ffffff30;
    border-radius: 30px;
    padding: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.Part svg {
    font-size: 120px;
    text-align: center;
    margin: 20px auto;
}

.Part.\30 1 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 1:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 1 button {
    background: #0095FF;
}

.Part.\30 2 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 2:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 2 button {
    background: #0095FF;
    border-color: #0095FF;
}

.Part.\30 3 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 3:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 3 button {
    background: #0095FF;
    border-color: #0095FF;
}

.Part.\30 4 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 4:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 4 button {
    background: #0095FF;
    border-color: #0095FF;
}


.Part.\30 5 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 5:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 5 button {
    background: #0095FF;
    border-color: #0095FF;
}


.Part.\30 6 {
    transition: 0.5s ease-out;
    transform-style: preserve-3d;
    background: #fff;
    padding: 25px;
    min-height: 400px;
    height: 100%;
}

.Part.\30 6:hover {
    transform: skewY(10deg);
    transform: skewY(0deg) perspective(200px) rotateX(4deg);
    background: #fff;
    padding: 25px;
    color: #000;
    transition: 0.5s ease-in-out;
    border: 2px dashed #99D5FF
}

.Part.\30 6 button {
    background: #0095FF;
    border-color: #0095FF;
}

.FormContent label {
    text-align: left;
    width: 100%;
    font-weight: 600;
}

.FormContent input {
    border: 1px solid #000;
}

.FormContent textarea.form-control {
    border: 1px solid #000;
}

.FeatureRowMobile.row .col {
    position: relative;
}

.ContactInfo {
    padding: 5px 40px;
    border-radius: 20px;
}

Form.Control.Feedback {
    text-align: left;
}

form.ContactForm {
    background: transparent;
    padding: 40px;
    border-radius: 30px;
}

.FormContent {
    padding: 50px;
    background: #ebf5ff;
    border-radius: 30px;
}

.FormHeading {
    padding: 20px;
    text-align: left;
}

.fullWidth {
    text-align: center;
}

button.ContactBtn {
    background: #0095ff;
    border-color: #0095ff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ServiceContent .Part svg {
    font-size: 60px;
    text-align: center;
    margin: 20px auto;
}

.ServiceContent .Part svg {
    color: #ebebeb;
}

.ServiceContent .Part:hover svg {
    color: #000;
}

.ServiceContent {
    padding: 10px;
    margin: 80px 0px;
}

a.me-3.text-black.TD-none {
    text-decoration: none;
    font-weight: 600;
}

.AboutUsText button.btn.btn-primary {
    width: 25%;
    background: #3dbadd;
    border-color: #3dbadd;
}

button.navbar-toggler.collapsed {
    /* background: #fff; */
    font-size: 24px;
    border: 0px;
    color: #fff;
}

.navChild.scrolled {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button.navbar-toggler.collapsed {
    background: #000;
    font-size: 24px;
    border: 0px;
    color: #fff;
    padding: 3px 5px;
    border: inset;
}

button.navbar-toggler {
    background: #000;
    font-size: 24px;
    border: 0px;
    color: #fff;
    padding: 3px 5px;
    border: inset;
    box-shadow: 0px 0px 0px #000 !important;
}

.FuturristicGame02 ul.nav.nav-tabs .nav-link.active {
    background: #0095ff;
    color: #fff;
    padding: 10px 40px;
    border-radius: 30px;
}


.FuturristicGame02 ul.nav.nav-tabs .nav-link {
    background: #ebf5ff;
    color: #000;
    padding: 10px 40px;
    border-radius: 30px;
    margin: 30px 10px;
}

.bannerIcon a svg {
    color: #000 !important;
}

.FuturristicGame02 ul.nav.nav-tabs {
    justify-content: center;
    align-items: center;
    border-bottom: 0px;
}

button.mobile-100 {
    margin: 0px auto;
    padding: 6px 30px;
}

.custom-modal {
    max-width: 80%;
    max-height: 50%;
}

.modal-content {
    width: 100%;
}

.StatsticSection {
    background: #212529;
    color: #fff;
    border-radius: 150px;
}


.BenefitContent1 {
    padding: 10px;
    background-color: #f2f8ff;
    height: 200px;
    /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%); */
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.BenefitContent2 {
    padding: 10px;
    background-color: #f2f8ff;
    height: 200px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.BenefitContent1 h5,
.BenefitContent2 h5 {
    text-align: center;
}

.BenefitContentMeta {
    border: 1px solid rgb(238, 236, 236);
    padding: 15px 20px;
    border-radius: 10px;
    text-align: left;
    height: 100%;
    background-color: white;
    /* box-shadow:-10px 10px 2px 1px rgb(170, 165, 165); */
}

.animationcol {
    position: relative;
}

.BenefitContentMetashadow {
    position: absolute;
    width: 90%;
    top: 10px;
    left: 5px;
    border-radius: 10px;
    background-color: rgb(235, 245, 255);
    min-height: 100%;
    z-index: -1;

}

.TechService:hover,
.BenefitContentMeta:hover {
    animation: move 5s;
}

.BenefitContentMeta.TechService:hover {
    transform: skew(2deg, 2deg);
    /* box-shadow: 0px 48px 50px 0px rgb(170, 165, 165);     */
}


/* Custom Css Start */
.slick-prev:before,
.slick-next:before {
    color: #000 !important;
    font-size: 1.5rem;
}

/* Custom Css end */

/*----------service container animation----------*/
@keyframes move {
    0% {
        transform: translatey(0);
    }

    20% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(-20px);
    }

}

.slick-list {
    -webkit-overflow-scrolling: touch;
    /* Enables momentum scrolling */
}

.Icon-24 {
    width: 80px;
    height: 80px;
}

img.img-fluid.serviceIcon {
    width: 60px;
    height: 60px;
    /* margin: 10px auto; */
}

a.Link.form-control {
    text-decoration: none;
    padding: 0px;
}

img.img-fluid.benefitIcons {
    width: 60px;
    margin: 10px 0px;
}

.OwnerDetail {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 7%) 0px 7px 29px 0px;
}

.ownerContainer {
    padding: 40px;
    background: #ebf5ff;
    border-radius: 5px;
}

img.img-fluid.Img-25 {
    width: 110px;
    border-radius: 15px;
}

.ToolContent {
    text-align: center;
    padding: 10px;
    margin: 0px auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 20px;
    height: 100%;
}

.termCondition ul li {
    font-size: 1.5rem;
    font-weight: 500;
}

.privacyPolicy ul li {
    font-size: 1.5rem;
    font-weight: 500;
}

.NavParent {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.navbar-scrolled {
    background-color: #000000c2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-brand,
.navbar-scrolled .btn {
    color: #000;
}

.navChild {
    transition: background-color 0.3s, box-shadow 0.3s;
}

.navbar-brand img {
    height: 100%;
}

.nav-link {
    color: #fff;
    transition: color 0.3s;
}

.btn {
    transition: color 0.3s, background-color 0.3s;
}

.feature-image {
    width: 15vw;
}

.AboutUsText .vstack h1:nth-child(1) {
    font-size: 3rem;
    text-align: left;
    font-weight: 800;
}

.AboutUsText .vstack h1:nth-child(2) {
    font-size: 3rem;
    text-align: right;
    padding-right: 2rem;
    font-weight: 800;
}

.OwnerDetailcard {
    display: flex;
    flex-direction: row;
    gap: 1rem !important;
    align-items: center;
    text-align: left;
}

.cursor-pointer {
    cursor: pointer;
}



.custom-blogcard {
    min-height: 300px;
    height: 100%;
}

.hover-effect {
    overflow: hidden;
}

.hover-effect img {
    object-fit: fill;
    transition: 1s ease;

}

.hover-effect img:hover {
    transform: scale(1.2);
}

.img-height-lg {
    height: 350px;
}

.img-height-sm {
    height: 300px;
}





/* --------------------media query--------------------- */

@media (min-width:2560px) {

    body {
        font-size: 2rem;
    }

    .AboutUsText h5 {
        font-size: 45px;
    }

    .AboutUsText h2 {
        font-size: 4rem;
    }

    .AboutUsVideo img {
        max-width: 800px;
    }

    .Part.\30 1,
    .Part.\30 2,
    .Part.\30 3,
    .Part.\30 4,
    .Part.\30 5,
    .Part.\30 6 {
        min-height: 560px;
    }

    p {
        font-size: 2rem;
    }

    .btn {
        font-size: 1.9rem;
    }

    h1 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2.75rem;
    }

    h5 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 2.5rem;
    }

    .container {
        max-width: 2300px;
    }

    .AboutUsText .vstack h1:nth-child(1) {
        font-size: 5.8rem;
    }

    .AboutUsText .vstack h1:nth-child(2) {
        font-size: 5.8rem;
        padding-right: 100px;
    }

    img.img-fluid.serviceIcon {
        width: 150px;
        height: 150px;
    }


    img.img-fluid.Img-25 {
        width: 175px;
    }

    .form-control {
        font-size: 2rem;
    }

    .fontSize-24 {
        font-size: 40px;
    }

    .fontSize-20 {
        font-size: 30px;
    }

    .Icon-24 {
        width: 150px;
        height: 150px;
    }

    h6 {
        font-size: 1.5rem;
    }

    .BenefitContent1,
    .BenefitContent2 {
        height: 260px;
    }

    .img-height-lg {
        height: 650px;
    }

    .img-height-sm {
        height: 500px;
    }

    .card-title {
        font-size: 2.25rem;
    }

    .rotating-headings h3 {
        font-size: 1.7em;
    }

    .rotating-headings {
        height: 3em !important;
    }

    .carousel-image {
        width: 90%;
    }

    .FeatureSubHeading {
        max-width: 400px;
    }

    .FeatureMainHeading {
        width: 500px;
    }

}

@media (max-width: 576px) {

    .AboutUsText .vstack h1:nth-child(1) {
        font-size: 2rem;
    }

    .fontSize-24 {
        font-size: 20px;
    }

    .custom-card.card {
        min-height: 140px;
    }

    .AboutUsText .vstack h1:nth-child(2) {
        text-align: center;
        font-size: 2rem;

    }

    .feature-image {
        width: 100%;
    }

    .text-container {
        height: 45px;
    }

    .card-row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }


    .card-row>div {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%;
    }

    .FeatureRowMobile.row {
        flex-wrap: nowrap;
        overflow: scroll;
        z-index: 9999;
        position: relative;
    }

    video.MainSliderVideo {
        height: 80vh;
        object-fit: fill;
    }

    p.parahalf {
        width: 100%;
    }

    img.img-fluid.GameTitleImg {
        width: 100%;
    }

    .carousel-caption {
        right: 5%;
        left: 5%;
    }

    span.badgeSpan {
        text-align: center;
        width: 100%;
    }

    .card-row .col {
        width: 80%;
    }

    img.DoItBG {
        height: 10vh;
    }

    .Part {
        padding: 20px;
        margin: 10px 0px;
    }

    .Part svg {
        font-size: 60px;
        margin: 10px auto;
    }

    .FeatureMobile {
        flex-wrap: nowrap;
        overflow: scroll;
    }

    .carousel.slide video.img-fluid {
        height: 70vh;
        width: 100%;
    }

    .AboutUs_Section {
        position: relative;
    }


    img.img-fluid.About_Us {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: -1;
        height: 650px;
        object-fit: cover;
    }

    .AboutUsText button.btn.btn-dark {
        width: 100%;
        margin-bottom: 20px;
    }

    .AboutUs {
        padding: 30px 10px;
    }

    .AboutUsText button.btn.btn-primary {
        width: 100%;
    }

    div#basic-navbar-nav {
        background: #000;
        padding: 30px;
        border-radius: 20px;
    }

    .FormContent {
        padding: 10px;
    }

    button.mobile-100 {
        width: 100%;
        padding: 5px 10px;
    }

    .privacyPolicy h4,
    h5 {
        font-size: 16px;
        text-align: justify;
    }

    .termCondition h4,
    h5 {
        font-size: 16px;
        text-align: justify;
    }

    .termCondition ul li {
        font-size: 16px;
    }

    .privacyPolicy ul li {
        font-size: 16px;
    }

    .CollabContainer {
        padding: 10px !important;
    }

    .img-height-lg,
    .img-height-sm {
        height: 250px;
    }

    .rotating-headings {
        height: 5em !important;
    }

    .mobile-banner {
        min-height: 40vh;
    }

}


@media (max-width : 320px) {
    .OwnerDetailcard {
        display: flex;
        flex-direction: column;
        gap: 1rem !important;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .custom-card.card {
        min-height: 130px;
    }

    .img-height-lg,
    .img-height-sm {
        height: 200px;
    }

    .rotating-headings {
        height: 7em !important;
    }



}

/*  collab page css */

.text-container {
    height: clamp(1em, 2vw, 3em);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.CollabAboutUs {
    background: #EBF5FF;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.rotating-headings {
    position: relative;
    width: 100%;
    height: 3.7em;
    overflow: hidden;
}

.rotating-headings h3 {
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    animation: slideInOut 15s infinite;
}

.rotating-headings h3:nth-child(1) {
    animation-delay: 0s;
}

.rotating-headings h3:nth-child(2) {
    animation-delay: 5s;
}

.rotating-headings h3:nth-child(3) {
    animation-delay: 10s;
}

/* Keyframes for sliding text effect */
@keyframes slideInOut {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    10% {
        opacity: 1;
        transform: translateY(0%);
    }

    30% {
        opacity: 1;
        transform: translateY(0%);
    }

    40% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.CollabBox {
    position: relative;
    min-height: 170px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 0px 10px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 7%) 0px 7px 29px 0px;
}


.CollabContainer {
    padding: 3rem;
    background: #ebf5ff;
    border-radius: 5px;
}

.collabFeatureCard {
    position: relative;
    height: 100%;
    min-height: 450px;
    background: #fff;
    padding: 0px 20px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 7%) 0px 7px 29px 0px;
}


.HeadingBox {
    width: 150px;
    height: 20px;
    position: absolute;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    background-color: #0c6efd;
    padding: 1rem;
    border-radius: .25rem;


}

.UsesCard01,
.UsesCard02 {
    padding: 2rem;
    background: #ffffff;
    position: relative;
}


.UsesCard01::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: #0F75D6 #0F75D6 transparent transparent;
}

.UsesCard02::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0px 50px 50px;
    border-color: transparent #0F75D6 transparent #0F75D6;
}

.collabBoxIcon {

    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutUsVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 100%;
}

.AboutUsVideo img {
    max-width: 100%;
    height: auto;
}

.FeatureMainHeading {
    position: relative;
    min-width: 250px;
    background-color: #0c6efd;
    text-align: center;
    padding: 5px;
}

.FeatureSubHeading {
    min-width: 200px;
    padding: 5px;
    position: relative;
    top: -15px;
    border-radius: .25rem;
    background-color: #0c6efd;
    margin: auto auto;
}

.list-style-none {
    list-style: none;
}